import React from "react";
import { FiFacebook, FiGithub, FiInstagram, FiLinkedin } from "react-icons/fi";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div class="shadows">
        <img
          src="/henning-hall.jpg"
          width="200px"
          height="200px"
          className="image"
          alt="Henning Hall"
        />
      </div>
      <h1>Henning Hall</h1>
      <p>
        Hello there!{" "}
        <span role="img" aria-label="hello">
          👋
        </span>
        <br />I develop web and mobile apps. You find me here:
      </p>
      <div className="Links">
        <Link
          href="https://www.linkedin.com/in/henninghall"
          icon={FiLinkedin}
          text="LinkedIn"
        />
        <Link
          href="https://github.com/henninghall"
          icon={FiGithub}
          text="Github"
        />
        <Link
          href="https://www.facebook.com/henninghall.se"
          icon={FiFacebook}
          text="Facebook"
        />
        <Link
          href="https://www.instagram.com/henninghall/"
          icon={FiInstagram}
          text="Instagram"
        />
      </div>
    </div>
  );
}

const Link = (props) => {
  const Icon = props.icon;
  return (
    <a
      className="App-link"
      href={props.href}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="icon">{Icon && <Icon />}</div>
      <span className="linkText">{props.text}</span>
    </a>
  );
};

export default App;
